<script>
export default {
    props: {
      empresa: { type: Object, required: true },
    },
  data() {
    return {
      titleBody: 'Incluir Histórico PAdrão',
      historicoPadrao: {
        id: -1,
        descricao: null,
        status: true
      },
    }
  },
  created() {},
  mounted() {
    this.$emit('newTitle', this.titleBody);
  },
  methods: {
    setPost() {
      this.$emit('doPost', this.historicoPadrao);
    },
  },
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <form id="form-centro-resultado" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="centro_resultado_id" class="col-form-label">Código</label>
                <div class="col">
                  <input disabled v-model="historicoPadrao.id" class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="centro_resultado_id">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-10">
              <div class="form-group">
                <label for="centro_resultado_descricao" class="col-form-label">Nome do Histórico Padrão</label>
                  <div class="col">
                    <input v-model="historicoPadrao.descricao" class="form-control" type="text" placeholder="Identificação do Histórico Padrão" id="centro_resultado_descricao">
                  </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-10">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-pagamento-touch"
                   v-model="historicoPadrao.status"
                   name="touch"
                   value="1"
                   unchecked-value="0"
                  >
                      Ativo
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPost">Gravar</button>
    </div>
  </div>
</div>
</template>